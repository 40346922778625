export const routes = {
  HOME: "/",
  ADMIN_DASHBOARD: "/dashboard",
  FORM_BUILDER: "/form-builder",
  VIEW_FORM: "/view-forms",
  SURVEY_ANALYSER: "/survey-analyser"
};
export const brand = {
  NAME: "Survey Analyser",
};

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";

const FormField = ({
  field,
  previewMode,
  onDelete,
  onUpdateLabel,
  onUpdatePlaceholder,
  onUpdateOptions,
}) => {
  const [newOption, setNewOption] = useState("");
  const [editingOptionIndex, setEditingOptionIndex] = useState(null);
  const [editingOptionValue, setEditingOptionValue] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const handleAddOption = () => {
    if (newOption.trim()) {
      onUpdateOptions([...field.options, newOption]);
      setNewOption("");
    }
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleEditOption = (index) => {
    setEditingOptionIndex(index);
    setEditingOptionValue(field.options[index]);
  };

  const handleSaveEditedOption = () => {
    const updatedOptions = [...field.options];
    updatedOptions[editingOptionIndex] = editingOptionValue;
    onUpdateOptions(updatedOptions);
    setEditingOptionIndex(null);
    setEditingOptionValue("");
  };

  const renderInput = () => {
    switch (field.field_type) {
      case "text":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item name={field.label} label={field.label}>
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item label={field.label} name={field.label}>
            <InputNumber
              placeholder={field.placeholder}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        );
      case "password":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item label={field.label}>
            <Checkbox>{field.placeholder}</Checkbox>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item label={field.label}>
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio value={option}>{option} </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "dropdown":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Select
              placeholder={field.placeholder}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={field.options.map((option, index) => ({
                value: option,
                label: option,
              }))}
            />
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item label={field.label} name={field.label}>
            <DatePicker />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item label={field.label}>
            <TextArea rows={4} placeholder={field.placeholder} />
          </Form.Item>
        );
      case "file":
        // return <input type="file" disabled={true} />;
        return (
          <Form.Item
            label={field.label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </Form.Item>
        );
      case "button":
        return (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {field.placeholder}
            </Button>
          </Form.Item>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  return (
    <div
      style={{
        marginBottom: "16px",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: previewMode ? "#e9ecef" : "#ffffff",
      }}
    >
      <div
        className="form-field-header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <label style={{ fontWeight: "bold" }}></label>
        {!previewMode && (
          <div className="form-field-actions">
            <button
              onClick={() => setShowDetails(!showDetails)}
              style={{
                marginRight: "8px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{ color: "rgb(80, 70, 229)" }}
              />
            </button>
            <button
              onClick={onDelete}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "#dc3545" }} />
            </button>
          </div>
        )}
      </div>
      {renderInput()}
      {!previewMode && showDetails && (
        <div className="form-field-details" style={{ marginTop: "16px" }}>
          <label>Label</label>
          <Input
            type="text"
            value={field.label}
            onChange={(e) => onUpdateLabel(e.target.value)}
            placeholder="Label"
            style={{ marginBottom: "8px", width: "100%" }}
          />
          <label>Placeholder</label>
          <Input
            type="text"
            value={field.placeholder}
            onChange={(e) => onUpdatePlaceholder(e.target.value)}
            placeholder="Placeholder"
            style={{ marginBottom: "8px", width: "100%" }}
          />
          {field.field_type === "radio" && (
            <div>
              {field.options.map((option, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  {editingOptionIndex === index ? (
                    <Input
                      type="text"
                      value={editingOptionValue}
                      onChange={(e) => setEditingOptionValue(e.target.value)}
                      style={{ marginRight: "8px", flexGrow: 1 }}
                    />
                  ) : (
                    <span style={{ flexGrow: 1 }}>{option}</span>
                  )}
                  {editingOptionIndex === index ? (
                    <button
                      onClick={handleSaveEditedOption}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#28a745",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditOption(index)}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  )}
                </div>
              ))}
              <Input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Add option"
                style={{ marginBottom: "8px", width: "100%" }}
              />
              <button
                onClick={handleAddOption}
                style={{
                  marginBottom: "8px",
                  width: "100%",
                  padding: "8px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Add Option
              </button>
            </div>
          )}
          {field.field_type === "dropdown" && (
            <div>
              {field.options.map((option, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  {editingOptionIndex === index ? (
                    <Input
                      type="text"
                      value={editingOptionValue}
                      onChange={(e) => setEditingOptionValue(e.target.value)}
                      style={{ marginRight: "8px", flexGrow: 1 }}
                    />
                  ) : (
                    <span style={{ flexGrow: 1 }}>{option}</span>
                  )}
                  {editingOptionIndex === index ? (
                    <button
                      onClick={handleSaveEditedOption}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#28a745",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditOption(index)}
                      style={{
                        marginLeft: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#007bff",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  )}
                </div>
              ))}
              <Input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Add option"
                style={{ marginBottom: "8px", width: "100%" }}
              />
              <button
                onClick={handleAddOption}
                style={{
                  marginBottom: "8px",
                  width: "100%",
                  padding: "8px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Add Option
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FormField;

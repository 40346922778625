import React, { useEffect } from "react";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./NavRoutes";
import { brand } from "./util/constants";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
 
function App({ instance }) {
  useEffect(() => {
    document.title = brand.NAME;
  }, []);
 
  return (
    <MsalProvider instance={instance}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <NavRoutes />
        </BrowserRouter>
      </DndProvider>
    </MsalProvider>
  );
}
 
export default App;
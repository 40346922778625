import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import Toolbox from "./ToolBox";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import FormField from "./FormField";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faCopy,
  faSave,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import axios from "axios";

const FormBuilder = () => {
  const [formItems, setFormItems] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [formTitle, setFormTitle] = useState("");

  // const { createForm } = useCreateForm();

  const navigate = useNavigate();
  const [, drop] = useDrop(() => ({
    accept: [
      "text",
      "checkbox",
      "radio",
      "dropdown",
      "date",
      "textarea",
      "email",
      "number",
      "password",
      "file",
      "button",
    ],
    drop: (item) => {
      addItemToForm(item);
    },
  }));
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const viewCreatedForms = () => {
    navigate("/view-forms");
  };

  const generateHtmlString = () => {
    return formItems
      .map((item) => {
        // switch (item.field_type) {
        //   case "text":
        //   case "email":
        //   case "number":
        //   case "password":
        //     return `<input type="${item.field_type}" placeholder="${item.label}" disabled />`;
        //   case "checkbox":
        //   case "radio":
        //     return `<label><input type="${item.field_type}" disabled /> ${item.label}</label>`;
        //   case "dropdown":
        //     return `<select disabled>${item.options
        //       .map((option) => `<option>${option}</option>`)
        //       .join("")}</select>`;
        //   case "date":
        //     return `<input type="date" disabled />`;
        //   case "textarea":
        //     return `<textarea placeholder="${item.label}" disabled></textarea>`;
        //   case "file":
        //     return `<input type="file" disabled />`;
        //   case "button":
        //     console.log("Button Added");
        //     return `<button type="button"></button>`;
        //   default:
        //     return `<div>Unknown field type</div>`;
        // }
        switch (item.field_type) {
          case "text":
            return `<Form.Item label="${item.label}"labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} name="${item.label}">
                <Input placeholder="${item.placeholder}" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}/>
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${item.label}" label="${item.label}">
                <Input placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <InputNumber
                  placeholder="${item.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Input.Password placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
              <Form.Item label="${item.label}">
                <Checkbox>"${item.placeholder}"</Checkbox>
              </Form.Item>`;
          case "radio":
            return `
              <Form.Item label="${item.label}">
                <Radio.Group>
                  ${item.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <Select
                  placeholder="${item.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                      .join("")}
                  options=${item.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${item.label}" name="${item.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${item.label}">
                <TextArea rows={4} placeholder="${item.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${item.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  "${item.placeholder}"
                </Button>
              </Form.Item>
            ;`;
          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };

  const handleCopyHtml = () => {
    const htmlString = generateHtmlString();
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };
  const formTitleChange = (e) => {
    setFormTitle(e.target.value);
  };

  const addItemToForm = (item) => {
    const newItem = {
      id: uuidv4(),
      field_type: item.type,
      label: item.label,
      required: false,
      options:
        item.type === "dropdown" || "radio"
          ? ["Option 1", "Option 2", "Option 3"]
          : [],

      placeholder: item.placeholder,
    };
    setFormItems((prevItems) => [...prevItems, newItem]);
  };

  const saveForm = async () => {
    if (!formTitle) {
      console.error("Please provide a title for your form.");
      toast.error("Please provide a title for your form.");
      // alert("Please provide a title for your form!");
      return; // Prevent saving if title is null
    }
    const payload = {
      title: formTitle,
      form_schema: formItems.map((item) => ({
        field_type: item.field_type,
        label: item.label,
        placeholder: item.placeholder,
        required: item.required,
        options: item.options,
      })),
    };

    console.log("pppppppppppp", payload);

    try {
      console.log("payload.form_fields:", payload);
      // await createForm(payload.form_fields);
      const response = await axios.post(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form",
        payload,
        {
          "Content-Type": "application/json",
        }
      );
      console.log("response:", response);
      toast.success("Form and fields saved successfully");
      // alert("Form and fields saved successfully!");
    } catch (error) {
      toast.error("Error saving form.");
      // alert("Error saving form.");
    }
  };

  return (
    <div>
      {" "}
      <ToastContainer position="top-center" />
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          minHeight: "100vh",
        }}
      >
        {/* Add Toolbox */}
        <div
          ref={drop}
          style={{
            padding: "10px 16px 10px 16px",
            minHeight: "400px",
            flexGrow: 1,
            margin: "16px",
            border: "1px solid rgb(43, 46, 74)",
            borderRadius: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#2B2E48",
              marginLeft: "20px",
            }}
          >
            <h3>{previewMode ? "Form Preview" : "Form Builder"}</h3>
            <div>
              <Input placeholder="Form Title" onChange={formTitleChange} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                onClick={() => setPreviewMode(!previewMode)}
                style={{
                  marginRight: "8px",
                  padding: "8px 16px",
                  // backgroundColor: previewMode ? "#6c757d" : "#007bff",
                  backgroundColor: previewMode ? "#6c757d" : "#2B2E48",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faEye} style={{ marginRight: "8px" }} />
                {previewMode ? "Back to Edit" : "Preview Form"}
              </button>
              {/* <button
              onClick={viewCreatedForms}
              style={{
                padding: "8px 16px",
                backgroundColor: "#5046e5",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faFileAlt}
                style={{ marginRight: "8px" }}
              />
              View Created Forms
            </button> */}
              {previewMode && (
                <>
                  <button
                    onClick={saveForm}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#28a745",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      marginRight: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      style={{ marginRight: "8px" }}
                    />
                    Save Form
                  </button>
                  <button
                    onClick={handleCopyHtml}
                    style={{
                      padding: "8px 16px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      style={{ marginRight: "8px" }}
                    />
                    Copy as HTML
                  </button>
                </>
              )}
            </div>
          </div>
          <hr style={{ color: "rgb(43, 46, 74)" }} />
          <Form layout="vertical">
            {formItems.map((item, index) => (
              <FormField
                style={{ width: "700px" }}
                key={index}
                field={item}
                previewMode={previewMode}
                onDelete={() =>
                  setFormItems(formItems.filter((i) => i.id !== item.id))
                }
                onUpdateLabel={(newLabel) =>
                  setFormItems(
                    formItems.map((i) =>
                      i.id === item.id ? { ...i, label: newLabel } : i
                    )
                  )
                }
                onUpdatePlaceholder={(newPlaceholder) =>
                  setFormItems(
                    formItems.map((i) =>
                      i.id === item.id
                        ? { ...i, placeholder: newPlaceholder }
                        : i
                    )
                  )
                }
                onUpdateOptions={(newOptions) =>
                  setFormItems(
                    formItems.map((i) =>
                      i.id === item.id ? { ...i, options: newOptions } : i
                    )
                  )
                }
              />
            ))}
          </Form>
        </div>
        <Toolbox />
      </div>
    </div>
  );
};

export default FormBuilder;

import React, { useEffect, useState } from "react";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faCopy,
  faTrash,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Upload,
  Model,
  Icon,
  Tag,
  message,
  Modal,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CopyOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ViewForms = () => {
  const [forms, setForms] = useState([]);
  // const [emails, setEmails] = useState([]);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [formURL, setFormURL] = useState("http://dummyLink/view-forms");
  const [shareDisable, setShareDisable] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const openMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Copying...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "Copied!",
        duration: 2,
      });
    }, 1000);
  };

  // const handleSearch = (value) => {
  //   const newEmail = value.trim();
  //   if (newEmail) {
  //     setEmails([...emails, newEmail]);
  //   }
  // };

  const antIcon = (
    <>
      <LoadingOutlined style={{ fontSize: 24 }} spin />
    </>
  );
  const copyLinkToClipboard = () => {
    // inputRef.current.select();
    openMessage();
    navigator.clipboard.writeText(formURL);
  };

  const [shareForm] = Form.useForm();
  shareForm.setFieldsValue({
    title: formTitle,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = async (values) => {
    setModalLoading(true);
    // messageApi.open({
    //   key,
    //   type: "loading",
    //   content: "Sending email...",
    // });
    // setTimeout(() => {
    //   messageApi.open({
    //     key,
    //     type: "success",
    //     content: "Copied!",
    //     duration: 2,
    //   });
    // }, 1000);
    console.log("title:", formTitle);
    console.log("emails:", values);
    const payload = {
      form_id: selectedValue,
      emails: emails,
    };
    await axios
      .post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/send-survey",
        payload
      )
      .then((res) => {
        console.log(res);
        setEmails([]);
        messageApi.open({
          key,
          type: "success",
          content: "Email sent successfully!",
          duration: 2,
        });
      })
      .catch((err) => {
        messageApi.open({
          key,
          type: "error",
          content: "Something went wrong!",
          duration: 2,
        });
      });
    showModal();

    setModalLoading(false);
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Add your email sending logic here, e.g., using a library like `react-emailjs`
  //   // console.log(`Sending email to: ${to}`);
  //   // console.log(`Subject: ${subject}`);
  //   // console.log(`Message: ${message}`);
  //   // axios
  //   //   .post(
  //   //     "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/send-survey",
  //   //     { title: subject, email: [to] }
  //   //   )
  //   //   .then((res) => {
  //   //     console.log(res);
  //   //   })
  //   //   .catch((err) => console.log(err));
  // };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (
      inputValue &&
      validateEmail(inputValue) &&
      !emails.includes(inputValue)
    ) {
      setEmails([...emails, inputValue]);
      setInputValue("");
      shareForm.setFieldsValue({
        emails: "",
        title: formTitle,
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Invalid email",
        duration: 2,
      });
    }
  };

  const handleRemoveEmail = (removedEmail) => {
    setEmails(emails.filter((email) => email !== removedEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  async function fetchSurveyForm() {
    try {
      const response = await axios.get(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=null"
      );
      console.log("response data", response.data);
      setSurveyData(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }
  const [surveyData, setSurveyData] = useState(null);

  useEffect(() => {
    fetchSurveyForm();

    // .catch(error => setError(error));
  }, []);

  function fun(selectedValue) {
    console.log("Hello, you selected:", selectedValue);
  }

  async function getFormDataByID(item) {
    try {
      const url = `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=${item}`;
      axios
        .get(url) // Use params object for GET requests
        .then((response) => {
          console.log(response.data[0].title);
          setForms(response.data);
          setFormTitle(response.data[0].title);
          setShareDisable(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching survey form:", error);
      throw error; // Re-throw the error to handle it appropriately in the calling component
    }
  }

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleDeleteClick = async (id) => {
    try {
      console.log("deleteFormField id:", id);
      // await deleteFormField(id);
      setForms(forms.filter((form) => form.id !== id));
      alert("Form deleted successfully!");
    } catch (error) {
      alert("Failed to delete form.");
    }
  };

  useEffect(() => {
    const loadForms = async () => {
      try {
        const item = localStorage.getItem("form-builder");
        setForms(item ? [JSON.parse(item)] : null);
      } catch (error) {}
    };

    // loadForms();
  }, []);

  const renderFormField = (field) => {
    const commonStyles = {
      width: "100%",
      padding: "8px",
      margin: "8px 0",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "16px",
      color: "#495057",
    };

    switch (field.field_type) {
      case "text":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item name={field.label} label={field.label}>
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "number":
        return (
          <Form.Item label={field.label} name={field.label}>
            <InputNumber
              placeholder={field.placeholder}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        );
      case "password":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item label={field.label}>
            <Checkbox>{field.placeholder}</Checkbox>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item label={field.label}>
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio value={option}>{option} </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );
      case "dropdown":
        return (
          <Form.Item label={field.label} name={field.label}>
            <Select
              placeholder={field.placeholder}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={field.options.map((option, index) => ({
                value: option,
                label: option,
              }))}
            />
          </Form.Item>
        );
      case "date":
        return (
          <Form.Item label={field.label} name={field.label}>
            <DatePicker />
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item label={field.label}>
            <TextArea rows={4} placeholder={field.placeholder} />
          </Form.Item>
        );
      case "file":
        // return <input type="file" disabled={true} />;
        return (
          <Form.Item
            label={field.label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </Form.Item>
        );
      case "button":
        return (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {field.placeholder}
            </Button>
          </Form.Item>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  // const generateEmbedCode = (slug) => {
  //   const embedUrl = `${API_URL}/api/v1/forms/${slug}`;
  //   return `<iframe src="${embedUrl}" width="100%" height="500px" frameborder="0"></iframe>`;
  // };

  const handleShareClick = (slug) => {
    console.log("slug:", slug);
    // const embedCode = generateEmbedCode(slug);
    // navigator.clipboard.writeText(embedCode);
    alert("Embed code copied to clipboard!");
  };

  const generateHtmlString = (formFields) => {
    return formFields
      .map((field) => {
        switch (field.field_type) {
          // {
          //   case "text":
          //   case "email":
          //   case "number":
          //   case "password":
          //     return `<input type="${field.field_type}" placeholder="${field.placeholder}" disabled />`;
          //   case "checkbox":
          //   case "radio":
          //     return `<label><input type="${field.field_type}" disabled /> ${field.label}</label>`;
          //   case "dropdown":
          //     return `<select disabled>${field.options
          //       .map((option) => `<option>${option}</option>`)
          //       .join("")}</select>`;
          //   case "date":
          //     return `<input type="date" disabled />`;
          //   case "textarea":
          //     return `<textarea placeholder="${field.placeholder}" disabled></textarea>`;
          //   case "file":
          //     return `<input type="file" disabled />`;
          //   default:
          //     return `<div>Unknown field type</div>`;
          // }
          case "text":
            return `<Form.Item label="${field.label}" name="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "email":
            return `
              <Form.Item name="${field.label}" label="${field.label}">
                <Input placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "number":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <InputNumber
                  placeholder="${field.placeholder}"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>`;
          case "password":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Input.Password placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "checkbox":
            return `
              <Form.Item label="${field.label}">
                <Checkbox>"${field.placeholder}"</Checkbox>
              </Form.Item>`;
          case "radio":
            return `
              <Form.Item label="${field.label}">
                <Radio.Group>
                  ${field.options
                    .map(
                      (option, index) =>
                        ` <Radio value=${option}>${option} </Radio>`
                    )
                    .join("")}
                </Radio.Group>
              </Form.Item>`;
          case "dropdown":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <Select
                  placeholder="${field.placeholder}"
                  filterSort=${(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                      .join("")}
                  options=${field.options
                    .map((option, index) => ({
                      value: option,
                      label: option,
                    }))
                    .join("")}
                />
              </Form.Item>`;
          case "date":
            return `
              <Form.Item label="${field.label}" name="${field.label}">
                <DatePicker />
              </Form.Item>`;
          case "textarea":
            return `
              <Form.Item label="${field.label}">
                <TextArea rows={4} placeholder="${field.placeholder}" />
              </Form.Item>`;
          case "file":
            // return <input type="file" disabled={true} />;
            return `
              <Form.Item
                label="${field.label}"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload action="/upload.do" listType="picture-card" maxCount={1}>
                  <button
                    style={{
                      border: 0,
                      background: "none",
                    }}
                    type="button"
                  >
                    <PlusOutlined />
                    <div
                      style={{
                        marginTop: 5,
                      }}
                    >
                      Upload
                    </div>
                  </button>
                </Upload>
              </Form.Item>`;
          case "button":
            return `
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  "${field.placeholder}"
                </Button>
              </Form.Item>
            `;
          default:
            return <div>Unknown Form Item</div>;
        }
      })
      .join("");
  };

  const handleCopyHtml = (formFields) => {
    let htmlString = generateHtmlString(formFields);
    const prefix = `<Form
    name="basic"
    layout="vertical"
    onFinish={onFinish}
    labelCol={{
          span: 2,
        }}
  >`;

    const suffix = `</Form>`;
    htmlString = prefix + htmlString + suffix;
    console.log("htmlString:", htmlString);
    navigator.clipboard.writeText(htmlString).then(() => {
      alert("HTML copied to clipboard!");
    });
  };

  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (value) => {
    console.log("valuev", value);
    getFormDataByID(value);
    setSelectedValue(value);
  };
  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "white",
        // minHeight: "100vh",
      }}
    >
      {contextHolder}
      <div
        // ref={drop}
        style={{
          padding: "10px 16px 10px 16px",
          // minHeight: "400px",
          flexGrow: 1,
          margin: "16px",
          border: "1px solid rgb(43, 46, 74)",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#2B2E48",
            marginLeft: "20px",
          }}
        >
          <h3>View Form</h3>
          <div>
            {/* <Input placeholder="Select Form" /> */}

            <Select
              placeholder="Select"
              style={{ minWidth: "200px" }}
              onChange={(value) => handleChange(value)}
            >
              {Array.isArray(surveyData)
                ? surveyData.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.title}
                    </Select.Option>
                  ))
                : null}
            </Select>
            {/* {selectedValue && <p>You selected: {selectedValue}</p>} */}
          </div>
          {!shareDisable && (
            <>
              <div></div>
              <div></div>
            </>
          )}
          {shareDisable && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                onClick={showModal}
                disabled={false}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#2B2E48",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faShare}
                  style={{ marginRight: "8px" }}
                />
                Share
              </button>

              <button
                onClick={() => handleCopyHtml(forms[0].form_schema)}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#28a745",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={faCopy} style={{ marginRight: "8px" }} />{" "}
                Copy as HTML
              </button>
            </div>
          )}
        </div>
        <Modal
          title="Share Form"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={
            [
              // <Button key="back" onClick={handleCancel}>
              //   Cancel
              // </Button>,
              // <button
              //   onClick={handleOk}
              //   style={{
              //     padding: "8px 16px",
              //     height: "30px",
              //     backgroundColor: "#2B2E48",
              //     color: "white",
              //     border: "none",
              //     borderRadius: "4px",
              //     cursor: "pointer",
              //     margin: "0px 0px 3px 10px",
              //   }}
              // >
              //   <FontAwesomeIcon icon={faShare} style={{ marginRight: "8px" }} />
              //   Share
              // </button>,
            ]
          }
        >
          <Spin
            indicator={antIcon}
            spinning={modalLoading}
            tip="Sending emails..."
          >
            <Form
              // loading={modalLoading}
              form={shareForm}
              name="basic"
              labelCol={{
                span: 4,
              }}
              // wrapperCol={{
              //   span: 8,
              // }}
              style={{
                maxWidth: 600,
              }}
              onFinish={handleOk}

              // onFinishFailed={onFinishFailed}
              // autoComplete="off"
            >
              <Form.Item label="Form Title" name="title">
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="emails"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  type="text"
                  placeholder="Enter email and press Enter"
                  value={inputValue}
                  onChange={handleInputChange}
                  onPressEnter={handleInputConfirm}
                  // onBlur={handleInputConfirm}
                />
              </Form.Item>
              {emails.map((email, index) => (
                <>
                  <Tag
                    style={{ height: "25px", margin: "0px 0px 5px 80px" }}
                    key={index}
                    closable
                    onClose={() => handleRemoveEmail(email)}
                  >
                    {email}
                  </Tag>
                  <br />
                </>
              ))}
              {/* <Form.Item
              name="link"
              label="Link"
              rules={[
                {
                  type: "url",
                  warningOnly: true,
                },
                {
                  type: "string",
                  min: 6,
                },
              ]}
            >
              <Input
                suffix={
                  <CopyOutlined
                    style={{ cursor: "pointer" }}
                    onClick={copyLinkToClipboard}
                  />
                }
              />
            </Form.Item> */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Form.Item label={null}>
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item label={null}>
                  <Button
                    key="back"
                    disabled={emails.length > 0 ? false : true}
                    onClick={handleOk}
                    style={{
                      backgroundColor: "#2B2E48",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      margin: "0px 0px 0px 20px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faShare}
                      style={{ marginRight: "8px" }}
                    />
                    Share
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </Modal>
        <hr style={{ color: "rgb(43, 46, 74)" }} />
        <Form layout="vertical" disabled={true}>
          {forms?.map((form) => (
            <div
              key={form.id}
              style={{
                // marginBottom: "24px",
                // padding: "16px",
                // border: "1px solid rgb(43, 46, 74)",
                // border: "1px solid #007bff",
                margin: "0 0 0 15px",

                // borderRadius: "8px",
                backgroundColor: "#ffffff",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3 style={{ marginBottom: "16px", color: "rgb(43, 46, 74)" }}>
                  {form.title}
                </h3>
              </div>

              {form.form_schema.map((field) => (
                <div key={field.id} style={{ marginBottom: "16px" }}>
                  <label
                    style={{
                      display: "block",
                      marginBottom: "4px",
                      color: "#495057",
                    }}
                  >
                    {/* {field.label} */}
                  </label>
                  {renderFormField(field)}
                </div>
              ))}
            </div>
          ))}
        </Form>
      </div>
    </div>
  );
};

export default ViewForms;

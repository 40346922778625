import React, { useState, useEffect } from "react";
import { Bar, Pie, Line, Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js";
import axios from "axios";
import styled from "styled-components";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
  RadialLinearScale
);

// Styled Components
const AnalyserWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const AnalyserContainer = styled.div`
  border: 1px solid rgb(43, 46, 74);
  border-radius: 8px;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
`;

const SelectContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  select {
    padding: 8px;
    min-width: 200px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  label {
    font-weight: 500;
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
`;

const ChartContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const LoadingSpinner = styled.div`
  align-self: center;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid rgb(43, 46, 74);
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: #ffebee;
`;

const SurveyAnalyser = () => {
  const [formTitles, setFormTitles] = useState([]);
  const [formId, setFormId] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [chartData, setChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);
  const [color, setColor] = useState("");
  const [analyser, setAnalyser] = useState("");
  const [sentiment, setSentiment] = useState("");

  const [videoKPIdata, setVideoKPIData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchSurveyForm();
  }, []);

  async function fetchSurveyForm() {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        "https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form?id=null"
      );
      setFormTitles(response.data);
    } catch (error) {
      console.error("Error fetching survey form:", error);
      setError("Failed to fetch survey forms");
    } finally {
      setLoading(false);
    }
  }

  const handleTitleSelect = async (id) => {
    setSelectedTitle(id);
    setSelectedEmail("");
    setChartData(null);

    if (id) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/form-users?form_id=${id}`
        );
        console.log("response.data:", response.data);
        setEmailList(response.data);
      } catch (error) {
        console.error("Error fetching survey form:", error);
        setError("Failed to fetch survey forms");
      } finally {
        setLoading(false);
      }
      // try {
      //   // Dummy email list
      //   const dummyEmails = [
      //     "ajay.redhu@yash.com",
      //     "yukta.nagle@yash.com",
      //     "shubhanshu.singh@yash.com",
      //     "prabhat.sutar@yash.com",
      //     "prajvi.jain@yash.com",
      //   ];

      //   setEmailList(dummyEmails);
      // } catch (error) {
      //   console.error("Error fetching email list:", error);
      //   setError("Failed to fetch email list");
      //   setEmailList([]);
      // } finally {
      //   setLoading(false);
      // }
    }
  };

  const handleEmailSelect = async (email) => {
    setChartData(null);
    setAnalyser("");
    console.log("email.id:", email);
    let filterData = {};
    setSelectedEmail(email);
    if (email) {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-analysis`
        );
        // console.log("response.data:", response.data);
        let data = response?.data?.body;
        filterData = data.filter((body) => body.id === email);
        console.log("videoKPIData:", filterData);
        if (filterData[0].hasOwnProperty("image_kpi")) {
          setAnalyser("Image");
        } else if (filterData[0].hasOwnProperty("video_kpi")) {
          setAnalyser("Video");
        }
        setVideoKPIData(filterData[0]);
      } catch (error) {
        console.error("Error fetching survey form:", error);
        setError("Failed to fetch survey forms");
      } finally {
        setLoading(false);
      }
      setLoading(true);
      setError("");
      try {
        if (filterData[0].hasOwnProperty("image_kpi")) {
          switch (filterData[0].image_kpi.overall_sentiment) {
            case "positive":
              setColor("green");
              setSentiment("Positive");
              break;
            case "negative":
              setColor("red");
              setSentiment("Negative");
              break;
            default:
              setColor("yellow");
              setSentiment("Neutral");
          }
        } else if (filterData[0].hasOwnProperty("video_kpi")) {
          switch (filterData[0].video_kpi.overall_sentiment) {
            case "positive":
              setColor("green");
              setSentiment("Positive");
              break;
            case "negative":
              setColor("red");
              setSentiment("Negative");
              break;
            default:
              setColor("yellow");
              setSentiment("Neutral");
          }
          let labels = ["Angry", "Disgust", "Fear", "Happy", "Sad"];
          let data = [
            filterData[0].video_kpi.average_emotion_scores?.angry,
            filterData[0].video_kpi.average_emotion_scores?.disgust,
            filterData[0].video_kpi.average_emotion_scores?.fear,
            filterData[0].video_kpi.average_emotion_scores?.happy,
            filterData[0].video_kpi.average_emotion_scores?.sad,
          ];
          let backgroundColor = [
            "#4caf50",
            "#f44336",
            "#ff9800",
            "#9c27b0",
            "#03a9f4",
          ];

          setChartData({
            labels,
            data,
            backgroundColor,
          });
          const emotionArray = Object.keys(
            filterData[0].video_kpi.frame_analysis
          ).map((frame) => {
            const { dominant_emotion } =
              filterData[0].video_kpi.frame_analysis[frame];
            return `${frame} ${dominant_emotion}`;
          });
          labels = emotionArray;
          data = Object.values(filterData[0].video_kpi.frame_analysis).map(
            (frame) => frame.emotion_score
          );
          backgroundColor = [
            "#4caf50",
            "#8BC34A",
            "#f44336",
            "#673AB7",
            "#ff9800",
            "#FFC107",
            "#9c27b0",
            "#009688",
            "#03A9F4",
            "#E6E6FA",
          ];
          setPieChartData({
            labels,
            data,
            backgroundColor,
          });
        }
        // const sentimentData = videoKPIdata.average_emotion_scores;
      } catch (error) {
        console.error("Error fetching sentiment data:", error);
        setError("Failed to fetch sentiment data");
      } finally {
        setLoading(false);
      }
    }
  };

  const getChartOptions = (title) => ({
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: title,
        font: { size: 16 },
      },
    },
  });

  return (
    <AnalyserWrapper>
      <AnalyserContainer>
        <h3>Survey Analyser</h3>
        <SelectContainer>
          <label htmlFor="form-title-select">Select a Form:</label>
          <select
            id="form-title-select"
            value={selectedTitle}
            onChange={(e) => handleTitleSelect(e.target.value)}
          >
            <option value="">-- Select a Form --</option>
            {formTitles.map((formData, index) => (
              <option key={index} value={formData.id}>
                {formData.title}
              </option>
            ))}
          </select>

          {selectedTitle && (
            <>
              <label htmlFor="email-select">Select Respondent:</label>
              <select
                id="email-select"
                value={selectedEmail}
                onChange={(e) => handleEmailSelect(e.target.value)}
              >
                <option value="">-- Select Email --</option>
                {emailList.map((email, index) => (
                  <option key={email.id} value={email.id}>
                    {email.shared_with}
                  </option>
                ))}
              </select>
            </>
          )}
        </SelectContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {loading && (
          <>
            <LoadingSpinner />
            <div
              style={{
                color: "rgb(43, 46, 74)",
                alignSelf: "center",
                fontWeight: "500",
              }}
            >
              Loading...
            </div>
          </>
        )}
        {analyser === "Image" && (
          <>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <h3>{analyser} Analyser</h3>
            <h3>
              Overall Sentiment :{" "}
              <span style={{ color: color }}>{sentiment}</span>
            </h3>
            <h3>
              Overall emotion :{" "}
              <span>{videoKPIdata.image_kpi.overall_emotion}</span>
            </h3>
          </>
        )}
        {analyser === "Video" && chartData && (
          <>
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <h3>{analyser} Analyser</h3>
            <h3>
              Overall Sentiment :{" "}
              <span style={{ color: color }}>{sentiment}</span>
            </h3>
            {/* </div> */}
            <ChartsGrid>
              <ChartContainer>
                <Bar
                  data={{
                    labels: chartData.labels,
                    datasets: [
                      {
                        label: "Sentiment Distribution",
                        data: chartData.data,
                        backgroundColor: chartData.backgroundColor,
                      },
                    ],
                  }}
                  options={getChartOptions("Sentiment Analysis - Bar Chart")}
                />
              </ChartContainer>
              <ChartContainer>
                <Pie
                  data={{
                    labels: pieChartData.labels,
                    datasets: [
                      {
                        data: pieChartData.data,
                        backgroundColor: pieChartData.backgroundColor,
                      },
                    ],
                  }}
                  options={getChartOptions(
                    "Sentiment Distribution - Pie Chart"
                  )}
                />
              </ChartContainer>
              {/* <ChartContainer>
              <Line
                data={{
                  labels: chartData.labels,
                  datasets: [
                    {
                      label: "Sentiment Trend",
                      data: chartData.data,
                      borderColor: "#2196f3",
                      tension: 0.1,
                    },
                  ],
                }}
                options={getChartOptions("Sentiment Trend - Line Chart")}
              />
            </ChartContainer>

            <ChartContainer>
              <Radar
                data={{
                  labels: chartData.labels,
                  datasets: [
                    {
                      label: "Sentiment Radar",
                      data: chartData.data,
                      backgroundColor: "rgba(54, 162, 235, 0.2)",
                      borderColor: "rgba(54, 162, 235, 1)",
                      pointBackgroundColor: "rgba(54, 162, 235, 1)",
                    },
                  ],
                }}
                options={getChartOptions("Sentiment Radar Chart")}
              />
            </ChartContainer> */}
            </ChartsGrid>
          </>
        )}
      </AnalyserContainer>
    </AnalyserWrapper>
  );
};

export default SurveyAnalyser;
